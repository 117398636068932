<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper p-0">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title  mb-0">
                {{ chapter.title }}
              </h2>
              <span
                v-if="chapter.onlyAdmin"
                class="badge bg-light-info"
              >
                admin
              </span>
              <span
                v-else
                class="badge bg-light-success"
              >
                public
              </span>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              href="#"
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to knowledge base"
              @click="$router.push({name: 'knowledge-base.index'})"
            ><i data-feather="chevron-left" /></a>
            <a
              v-if="user.roles.includes('super-admin')"
              href="#"
              class="btn btn-icon btn-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Edit this content"
              @click="$router.push({name: 'knowledge-base.edit', params: { id: chapter.id }})"
            ><i data-feather="edit-2" /></a>
          </div>
        </div>
      </div>
      <div class="content-body">

        <!-- Knowledge base question Content  -->
        <section id="knowledge-base-question">
          <div class="row">
            <div class="col-lg-3 col-md-5 col-12 order-2 order-md-1">
              <div class="card">
                <div class="card-body">
                  <h6 class="kb-title">
                    <i
                      data-feather="info"
                      class="font-medium-4 me-50"
                    /><span>{{ section.title }}</span>
                  </h6>

                  <ul class="list-group list-group-circle mt-2">
                    <li
                      v-for="(otherChapter, index2) in section.chapters"
                      :key="index2"
                      class="list-group-item"
                      :class="otherChapter.id == chapter.id ? 'active' : ''"
                    >
                      <a
                        href="#"
                        class="text-body"
                        @click="changeChapter(otherChapter.id)"
                      >{{ otherChapter.title }}</a>
                      <span
                        v-if="otherChapter.onlyAdmin == true"
                        class="badge bg-light-info"
                      >admin</span>
                      <span
                        v-if="otherChapter.onlyAdmin == false"
                        class="badge bg-light-success"
                      >public</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-9 col-md-7 col-12 order-1 order-md-2">
              <div class="card">
                <div
                  class="card-body"
                  v-html="contentWithFilter != undefined ? contentWithFilter : chapter.content"
                />
              </div>
              <hr>
              <!-- <div class="card">
                <div class="card-body">
                  <h3>Especific training</h3>
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate voluptatibus quas amet. Facere obcaecati molestias maxime debitis veritatis vitae doloremque non quas quasi assumenda, ipsam, quod id deserunt dolor aut?</p>
                  <p><a
                    href="../training/training-course.php"
                    class="btn btn-outline-primary"
                  >Acces course</a></p>
                </div>
              </div> -->
            </div>
          </div>
        </section>
        <!-- Knowledge base question Content ends -->

        <!-- <div class="row">
          <div class="col-sm-6">
            <div class="card">
              <div class="card-body">
                <h2>Can't find the help you need?</h2>
                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illo obcaecati doloribus, accusamus architecto laboriosam distinctio dolorum aspernatur nisi ducimus deserunt doloremque cupiditate excepturi ea at veritatis quasi, minima quae iste.</p>
                <p><a
                  href="../contact/index.php"
                  class="btn btn-primary btn-sm"
                >Contact us</a></p>
                <picture>
                  <img
                    src="../../../../app-assets/images/illustration/contact-card.svg"
                    alt="Contact us"
                    class="img-fluid"
                  >
                </picture>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="card">
              <div class="card-body">
                <h2>Training space</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil tempore unde asperiores, incidunt officia cum enim et laborum quaerat, adipisci beatae itaque amet voluptates quod aperiam iste corporis obcaecati recusandae!</p>
                <p><a
                  href="../training/index.php"
                  class="btn btn-primary btn-sm"
                >Access</a></p>
                <picture>
                  <img
                    src="../../../../app-assets/images/illustration/training-card.svg"
                    alt="Training"
                    class="img-fluid"
                  >
                </picture>
              </div>
            </div>
          </div>
        </div> -->

      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      chapterId: this.$route.params.id,
      contentWithFilter: undefined,
    }
  },
  computed: {
    ...mapGetters({
    //   fields: 'modals/tableSettingsFields',
    //   allSections: 'knowledgeBase/allSections',
      chapter: 'knowledgeBase/chapter',
      section: 'knowledgeBase/section',
      searchedWords: 'knowledgeBase/searchedWords',
      user: 'auth/admin',
    }),
  },
  async mounted() {
    await this.$store.dispatch('knowledgeBase/fetchChapter', this.chapterId)
    await this.$store.dispatch('knowledgeBase/fetchSection', this.chapter.section.id)
    this.checkSearchedWords()
  },
  methods: {
    changeChapter(newChapterId) {
      this.$store.dispatch('knowledgeBase/fetchChapter', newChapterId)
      // this.$store.dispatch('knowledgeBase/cleanSearchedWords')
      this.checkSearchedWords()
    },
    checkSearchedWords() {
      if (this.searchedWords !== '') {
        this.contentWithFilter = this.chapter.content.replace(this.searchedWords, `<mark>${this.searchedWords}</mark>`)
      }
    },
  },

}
</script>

<style>

</style>
